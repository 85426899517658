@import '../../styles/variables';
@import '../../styles/mixins/dropdown';
@import '../../styles/mixins/paragraph';
@import '../../styles/mixins/header';
@import '../../styles/mixins/mediaqueries';

.innerContainer {
    &.hidden {
        display: none;
    }
    .bookingDetails {
        @include paragraph(18px, $orange);
        margin: 10px 0;
        &:last-of-type {
            margin-bottom: 40px;
        }
    }
    @include smMax {
        width: auto;
    }
    width: 900px;
    .title {
        @include header(36px, $orange);
        align-self: flex-start;
        margin: 20px 0;
    }
    .dropdown {
        @include dropdown;
        max-width: 350px;
    }
    .chosen {
        @include paragraph(18px, $orange);
        margin-left: 10px;
    }
    .iconContainer {
        display: flex;
        align-items: center;
        margin-top: 20px;
        &:last-of-type {
            margin-bottom: 20px;
        }
    }
    .goBackContainer {
        display: flex;
        justify-content: center;
        flex-direction: column;
        .back {
            color: $orange;
            font-size: 28px;
            display: flex;
            align-items: center;
            background: none;
            border: none;
            &:hover {
                cursor: pointer;
            }
            small {
                @include paragraph(14px, $orange);
                margin-left: 10px;
            }
        }
    }
}

.timePicker {
    display: flex;
    align-items: center;
    height: 50px;
    margin-top: 20px;
    z-index: 9;
    a {
        top: unset;
    }
    input {
        background-color: transparent;
        border: 2px solid $orange;
        border-radius: 0;
        color: $orange !important;
        height: 40px;
        @include paragraph(16px);
        &:hover {
            cursor: pointer;
        }
        &::placeholder {
            color: $orange;
        }
    }
}