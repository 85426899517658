@import '../../styles/mixins/header';
@import '../../styles/mixins/paragraph';
@import '../../styles/variables';
@import '../../styles/mixins/mediaqueries';

.roomCarouselWrapper {
	// height: 1100px;
	@include smMax {
		z-index: 9;
		height: auto;
	}
	h1 {
		@include header(96px);
		text-transform: uppercase;
		margin: 0;
		text-align: center;
		@include smMax {
			@include header(46px);
		}
	}
	h2 {
		@include header(30px, $orange);
		margin: 0;
		text-align: center;
	}
	.roomCarousel {
		width: 900px;
		// height: 450px;
		margin: 80px 0;
		.oneRoom {
			&:focus {
				outline: none;
			}
			&:hover {
				cursor: pointer;
			}
			&.activeRoom {
				position: relative;
				&:before {
							content: '';
							position: absolute;
							width: 100%;
							height: 100%;
							background: rgba($color: #000000, $alpha: 0.9);
				}
			}
			display: flex !important;
			justify-content: center;
			position: relative;
			p {
				@include header(28px);
				position: absolute;
				left: 10px;
				bottom: 10px;
				&:before {
					display: inline-block;
					content: 'Szoba';
					text-transform: uppercase;
					transform: rotate(-90deg);
					position: relative;
					top: -10px;
					font-size: 22px;
				}
			}
			h1 {
				@include header(36px);
				align-self: flex-end;
			}
		}
		.roomDetailContainer {
			display: flex;
			margin: 40px 0;
			flex-direction: column;
			justify-content: flex-start;
			.roomName {
				@include header(42px, $orange);
				text-align: left;
				margin-bottom: 20px;
			}
			.desc {
				@include paragraph(18px, $orange);
				text-align: left;
				line-height: 1.4;
			}
			.metaContainer {
				padding: 20px 0;
				display: flex;
				align-items: center;
				.oneMeta {
					&:last-of-type {
						&:after {
							content: unset;
						}
					}
					display: flex;
					align-items: center;
					&:after {
						content: '';
						height: 30px;
						width: 2px;
						background-color: $orange;
						margin: 0 10px;
					}
					span {
						@include paragraph(22px, $orange);
						margin-left: 10px;
					}
				}
			}
		}
	}
}

.prices {
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		li {
			@include paragraph(22px, $orange);
		}
	}
}