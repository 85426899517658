@import '../../styles/variables.scss';
@import '../../styles/mixins/paragraph';
@import '../../styles/mixins/mediaqueries.scss';

.topHeaderContainer {
    width: 100%;
    height: 40px;
    background-color: $orange;
    display: flex;
    align-items: center;
    padding: 10px 0;
    padding-left: 10px;
    p {
        @include paragraph(18px, #000);
        margin-right: 20px;
    }
    @include smMax {
        flex-direction: column;
        height: unset;
        width: unset;
        justify-content: flex-start;
    }
}