@import '../../styles/variables';
@import '../../styles/mixins/header';
@import '../../styles/mixins/paragraph';

.mobileRoomsContainer {
    width: calc(100% - 40px);
    overflow: hidden;
    .oneRoom {
        margin-top: 20px;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            background: rgba($color: #000000, $alpha: 0.5);
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
        }
        p {
            @include paragraph(16px);
            padding: 30px;
            line-height: 1.4;
            position: relative;
        }
        h1 {
            @include header(30px, $orange);
            padding: 30px 0;
        }
        &:last-of-type {
            margin-bottom: 40px;
        }
    }
    .metaContainer {
        .playerCounter {
            margin-left: 10px;
        }
        width: 100%;
        position: absolute;
        bottom: 0;
        display: flex;
        .icon {
            margin-bottom: 10px;
            margin-left: 10px;
            span {
                @include paragraph(14px, $orange);
            }
        }
    }
}