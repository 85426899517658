@import '../../../styles/variables';
@import '../../../styles/mixins/paragraph';

.inputContainer {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    input {
        height: 40px;
        background-color: #000;
        border: 2px solid $orange;
        z-index: 9;
        @include paragraph(16px);
        &:focus {
            outline: none;
            background-color: $orange;
            color: #000;
        }
        &::placeholder {
            color: $orange;
        }
    }
}