/* FONTS */

@import url('https://fonts.googleapis.com/css?family=KoHo|Roboto&display=swap');
@import './fonts';

/* COLORS */

$orange: #ffc654;

/* BREAKPOINTS */

$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;