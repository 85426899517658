@import '../../../styles/variables';
@import '../../../styles/mixins/paragraph';

.buttonContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    button {
        width: 100%;
        &:hover {
            cursor: pointer;
            &:before {
                background: linear-gradient(0deg,#ffc654,#b48017);
            }
        }
        box-shadow: none;
        background: none;
        border: none;
        position: relative;
        &:focus {
            outline: none;
        }
        @include paragraph(16px, #000);
        text-transform: uppercase;
        padding: 20px;
        z-index: 1;
        &:before {
            content: '';
            z-index: -2;
            background: linear-gradient(180deg,#ffc654,#b48017);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
        &:disabled  {
            background: #ccc;
            opacity: .5;
            &:hover {
                cursor: not-allowed;
            }
            &:before {
                content: unset;
            }
        }
    }
}