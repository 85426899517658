@import '../variables.scss';
@import '../mixins/paragraph';

@mixin dropdown ($background-color:$orange, $border-color:$orange, $border-radius: 0, $placeholder-color: #fff) {
    .dropdown {
        display: block;
        background-color: $background-color;
        border-color: $border-color;
    }
    
    .dropdownControl {
        display: block;
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        background-color: inherit;
        border-radius: $border-radius;
        border: 1px solid $border-color;
        @include paragraph(16px, $orange);
        border-bottom: 0;
        padding-left: 10px;
    }
    
    .placeholder {
        color: $orange;
    }
    
    .arrow {
        top: unset !important;
        border-color: $border-color transparent transparent;
    }
}