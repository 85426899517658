@import '../../styles/mixins/header';
@import '../../styles/mixins/paragraph';
@import '../../styles/mixins/mediaqueries';

.welcomeContainer {
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, transparent 9%, #000 100%);
        z-index: 9;
    }
    .welcomeTextContainer {
        span {
            @include header(40px, $orange);
        }
        position: absolute;
        z-index: 9;
        left: 60%;
        top: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @include smMax {
            top: 30%;
            left: 10px;
        }
        h1 {
            margin: 0;
            padding: 0;
            @include header(44px);
            text-transform: uppercase;
        }
    }
}