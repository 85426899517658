.diffBarContainer {
    height: 80px;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    overflow: hidden;
    position: absolute;
    bottom: 10px;
    right: 0;
    .hardnessBar {
        width: 4px;
        display: block;
        background: #fff;
        margin: 0 1px;
        overflow: hidden;
        position: relative;
    }
    &.hidden {
        display: none;
        visibility: hidden;
        width: 0;
        height: 0;
        opacity: 0;
    }
}