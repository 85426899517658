@import '../../../styles/mixins/mediaqueries';

.sectionWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .imageContainer {
        display: inherit;
        align-self: flex-end;
        position: absolute;
        top: 50%;
        z-index: 9;
        @include smMax {
            display: none;
        }
    }
}