@import '../../styles/variables';
@import '../../styles/mixins/header';
@import '../../styles/mixins/mediaqueries';

.navigation {
    @include smMax {
        display: none;
    }
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: absolute;
    z-index: 9;
    display: flex;
    width: 900px;
    justify-content: space-between;
    height: 60px;
    align-items: center;
    align-self: center;
    margin: 80px 0;
    &:before {
        content: "";
        display: block;
        position: absolute;
        background: linear-gradient(90deg,transparent,#6a5326,transparent);
        left: 0;
        width: 100%;
        height: 1px;
        animation-name: l;
        animation-duration: 5.5s;
        animation-fill-mode: both;
        animation-delay: 3s;
        top: 0;
    }
    &:after {
        content: "";
        display: block;
        position: absolute;
        background: linear-gradient(90deg,transparent,#6a5326,transparent);
        left: 0;
        width: 100%;
        height: 1px;
        animation-name: l;
        animation-duration: 5.5s;
        animation-fill-mode: both;
        animation-delay: 3s;
        bottom: 0;
    }
    .navItem {
        margin: 0;
        padding: 0;
        a {
            text-decoration: none;
            @include header(22px, #fff);
            text-transform: uppercase;
            position: relative;
            &:hover {
                @include header(22px, #ffc654);
                cursor: pointer;
                transition: all .4s ease;
                &:after {
                    content: '';
                    position: absolute;
                    bottom: -10px;
                    width: 100%;
                    height: 2px;
                    background-color: #ffc654;
                    left: 0;
                    transition: all .4s ease;
                }
            }
        }
    }
}