@import '../../styles/mixins/header';
@import '../../styles/mixins/mediaqueries';

.contactContainer {
    width: 900px;
    padding-bottom: 60px;
    @include smMax {
        max-width: calc(100% - 40px);
    }
}

.onlyButton {
    padding-bottom: 60px;
}

.title {
    @include header(36px, $orange);
    align-self: flex-start;
    margin: 20px 0;
}