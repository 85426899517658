@import '../../styles/mixins/mediaqueries';

@keyframes e {
    0% {
        opacity: 0;
        right: 0
    }

    15% {
        opacity: 1
    }

    17% {
        opacity: .3
    }

    20% {
        opacity: .9
    }

    21% {
        opacity: .1
    }

    22% {
        opacity: .9
    }

    25% {
        opacity: .1
    }

    35% {
        opacity: .9
    }

    36% {
        opacity: .1
    }

    45% {
        opacity: 1;
        right: 1px
    }

    46% {
        opacity: .1;
        right: 0
    }

    47% {
        opacity: 1;
        right: -1px
    }

    48% {
        opacity: .1;
        right: 0
    }

    49% {
        opacity: 1;
        right: 1px
    }

    50% {
        opacity: .1;
        right: 0
    }

    51% {
        opacity: 1;
        right: -1px
    }

    52% {
        opacity: .1;
        right: 0
    }

    54% {
        opacity: 1
    }

    55% {
        opacity: .1
    }

    56% {
        opacity: 1
    }

    57% {
        opacity: .1
    }

    58% {
        opacity: 1
    }

    65% {
        opacity: .1
    }

    75% {
        opacity: 1
    }

    85% {
        opacity: .3
    }

    95% {
        opacity: 1
    }

    96% {
        opacity: 0
    }

    97% {
        opacity: .9
    }

    to {
        opacity: 0
    }
}


@keyframes f {
    0% {
        right: 0
    }

    45% {
        right: 1px
    }

    46% {
        right: 0
    }

    47% {
        right: -1px
    }

    48% {
        right: 0
    }

    49% {
        right: 1px
    }

    50% {
        right: 0
    }

    51% {
        right: -1px
    }

    52% {
        right: 0
    }
}

.flickering {
    animation: e 10s ease 5s;
    animation-iteration-count: infinite;
    @include smMax {
        left: 0;
    }
}


.bulb {
    animation: f 10s ease 20s;
}

.bulbContainer {
    position: absolute;
    left: 0;
    width: 400px;
    @include smMax {
        width: 100%;
        z-index: -1;
        opacity: .3;
        left: 50px;
        top: -500px;
    }
}