@import '../../styles/variables';

.lakatalatt-booking {
    .react-calendar__navigation__arrow {
        &:disabled {
            opacity: 0;
        }
    }
    border: 2px solid $orange;
    background: #000;
    .react-calendar__month-view__weekdays {
        div {
            abbr {
                color: $orange;
            }
        }
    }
    button {
        &:focus {
            background: none !important;
        }
        &:hover {
            background-color: $orange !important;
            color: #fff;
        }
        color: $orange;
        &.react-calendar__tile--active {
            background-color: $orange !important;
            color: #fff;
        }
        &.react-calendar__tile {
            &:disabled {
                background-color: rgba($color: $orange, $alpha: 0.5);
                &:hover {
                    cursor: not-allowed;
                }
            }
        }
    }
}